/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import PompayTopLogo from 'shared/PompayTopLogo'
// import { MdSearch } from 'react-icons/md';
import moment from 'moment'
import SidebarNavigation from 'components/account/SidebarNavigation'
import { Doughnut, Bar } from 'react-chartjs-2'
import { GoPrimitiveDot } from 'react-icons/go'
import { MdChevronRight } from 'react-icons/md'
import {
    getPatientReviewListing,
    getReviewArrangementDetails,
    acceptPatientArrangement,
    updateArrangement,
    downloadArrangementPdf,
} from 'actions/patientActions'
import {
    currencyFormatter,
    numberFormatter,
    bolderNameFormatter,
} from 'utils/helper'
import { Table, Space } from 'antd'
import PatientArrangementDetailsModal from './PatientArrangementDetailsModal'
import Loading from 'shared/Loader'
import { colorCode, hoverColorCode, isMobile } from 'constants/Constant'
import PhysicianDashboardMobile from '../mobile/PhysicianDashboardMobile'
import { getTotalAmount } from 'actions/bankingActions'
import SuccessAlert from 'shared/SuccessAlert'

function PhysicianDashboard(props) {
    const dispatch = useDispatch()
    const [isArrangementDetails, setIsArrangementDetails] = useState(false)
    const [successAlert, setSuccessAlert] = useState(false)
    const [arrangementDetails, setArrangementDetails] = useState({})
    const [pieChartData, setPieChartData] = useState({})
    const [barChartData, setBarChartData] = useState({})
    const [pastDuePayments, setPastDuePayments] = useState(0)
    const [paidAmount, setPaidAmount] = useState(0)
    const [unPaidAmount, setUnPaidAmount] = useState(0)
    // const [ serchValue, setSerchValue ] = useState('');
    const [successAlertMessage, setSuccessAlertMessage] = useState('')
    const [filteredData, setFilteredData] = useState([])
    const [selectedData, setSelectedData] = useState([])
    const [input, setInput] = useState({})
    const totalAmount = useSelector(
        (state) => state.totalAmountStripe.total_balance
    )
    const availableToPayOutAmount = useSelector(
        (state) => state.totalAmountStripe.available_to_payout
    )
    const isLoading = useSelector((state) => state.applicationIsLoading)
    useEffect(() => {
        dispatch(getTotalAmount())
        dispatch(getPatientReviewListing()).then((res) => {
            if (res.statusCode === 200) {
                const ArrayData = res.data.review_arrangements.filter(
                    (item) => item.is_arrangement_accepted === 0
                )
                setFilteredData(ArrayData.splice(0, 3))
                setPastDuePayments(res.data.past_dues_payments)
                setPieChartData(res.data.pie_chart_data)
                setBarChartData(res.data.bar_graph_data)
                setPaidAmount(res.data.paid_amounts)
                setUnPaidAmount(res.data.unpaid_amounts)
            }
        })
    }, [])
    const pieAmountArray = []
    const pieLabelArray = []
    pieChartData && Object.keys(pieChartData).length > 0
        ? pieChartData.map((d) => {
              return pieAmountArray.push(
                  d && d.total_amount && d.total_amount.toFixed(2)
              )
          })
        : []
    pieChartData && Object.keys(pieChartData).length > 0
        ? pieChartData.map((d) => {
              return pieLabelArray.push(d.days_between)
          })
        : []
    const donutData = {
        labels: pieLabelArray,
        datasets: [
            {
                labels: {
                    render: 'label',
                },
                data: pieAmountArray,
                backgroundColor: colorCode,
                hoverBackgroundColor: hoverColorCode,
                borderWidth: 3,
            },
        ],
    }
    const barLabelArray = []
    barChartData && Object.keys(barChartData).length > 0
        ? barChartData.map((d) => {
              return barLabelArray.push(
                  moment(d.date).format('ddd, M/D').split(',')
              )
          })
        : []
    const barPaidArray = []
    barChartData && Object.keys(barChartData).length > 0
        ? barChartData.map((d) => {
              return barPaidArray.push(d.paid_amounts)
          })
        : []
    const barUnpaidArray = []

    barChartData && Object.keys(barChartData).length > 0
        ? barChartData.map((d) => {
              return barUnpaidArray.push(d.unpaid_amounts)
          })
        : []
    const barAmountArray = []
    barChartData && Object.keys(barChartData).length > 0
        ? barChartData.map((d) => {
              return barAmountArray.push(d.total_amounts)
          })
        : []
    const finalBardata = {
        labels: barLabelArray,
        previousDate: {
            // label: '08/10/2019 - 09/30/2019',
            dataSet: barPaidArray,
        },
        currentDate: {
            // label: '10/01/2019 - 11/20/2019',
            dataSet: barUnpaidArray,
        },
    }

    const handleAcceptArrangement = (key) => {
        const data = {
            is_arrangement_accepted: 1,
            patient_payment_ids: [key.patient_payment_id],
        }
        dispatch(acceptPatientArrangement(data)).then((response) => {
            if (response.statusCode === 200) {
                setSuccessAlert(true)
                setSuccessAlertMessage(
                    'You have successfully accepted this arrangement'
                )
                setArrangementDetails(false)
                dispatch(getPatientReviewListing('')).then((res) => {
                    if (res.statusCode === 200) {
                        setIsArrangementDetails(false)
                        const ArrayData = res.data.review_arrangements.filter(
                            (item) => item.is_arrangement_accepted === 0
                        )
                        setFilteredData(ArrayData.splice(0, 3))
                        //         setFilteredData(
                        //             res.data &&
                        // res.data.review_arrangements &&
                        // res.data.review_arrangements.splice(0, 3)
                        //         );
                        setPaidAmount(res.data.paid_amounts)
                        setUnPaidAmount(res.data.unpaid_amounts)
                    }
                })
            }
        })
    }
    const handleOpenArrangementModal = (key) => {
        setIsArrangementDetails(true)
        setArrangementDetails(key)
        dispatch(getReviewArrangementDetails(key.patient_payment_id)).then(
            (res) => {
                if (res.statusCode === 200) {
                    setInput(res.data)
                }
            }
        )
    }
    const columns = [
        {
            title: 'Name',
            dataIndex: '',
            sorter: (a, b) => a.first_name.length - b.first_name.length,
            sortDirections: ['ascend', 'descend'],
            render: (key) => (
                <div className="patient-name">{bolderNameFormatter(key)}</div>
            ),
        },
        {
            title: 'Patient I.D',
            dataIndex: 'patient_appointment_id',
        },
        {
            title: 'Discount Applied',
            dataIndex: 'discount_applied',
            render: (discount_applied) => <>{discount_applied}</>,
            sorter: (a, b) =>
                a.discount_applied.length - b.discount_applied.length,
        },
        {
            title: 'Original Amount',
            dataIndex: 'original_amount',
            render: (original_amount) => (
                <>{currencyFormatter.format(original_amount)}</>
            ),
            sorter: (a, b) =>
                a.original_amount.length - b.original_amount.length,
        },
        {
            title: 'Discount Amount',
            dataIndex: 'discount_amount',
            render: (discount_amount) => (
                <>{currencyFormatter.format(discount_amount)}</>
            ),
            sorter: (a, b) =>
                a.discount_amount.length - b.discount_amount.length,
        },
        {
            title: 'Adjusted Amount',
            dataIndex: 'adjusted_amount',
            render: (adjusted_amount) => (
                <>{currencyFormatter.format(adjusted_amount)}</>
            ),
            sorter: (a, b) =>
                a.adjusted_amount.length - b.adjusted_amount.length,
        },
        {
            title: '',
            dataIndex: '',
            key: 'x',
            render: (key) => (
                <Space size="middle">
                    <button
                        className="btn btn-link edit-arrangement"
                        onClick={() => handleOpenArrangementModal(key)}
                        disabled={key.is_arrangement_accepted}
                    >
                        Edit
                    </button>
                </Space>
            ),
        },
        {
            title: '',
            dataIndex: '',
            key: 'x',
            render: (key) => (
                <>
                    {!key.is_arrangement_accepted ? (
                        <button
                            className="btn accept-btn"
                            onClick={() => handleAcceptArrangement(key)}
                        >
                            Accept
                        </button>
                    ) : (
                        <span className="accepted-label">Accepted</span>
                    )}
                </>
            ),
            responsive: ['sm', 'lg', 'md'],
        },
    ]
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedData(selectedRows)
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === 'Disabled User',
            // Column configuration not to be checked
            name: record.name,
        }),
    }

    const tableData = filteredData
    // filteredData.filter((d) => {
    //     return (
    //         (d.first_name &&
    //       d.first_name.toLowerCase().indexOf(serchValue.toLowerCase()) !==
    //         -1) ||
    //     (d.last_name &&
    //       d.last_name.toLowerCase().indexOf(serchValue.toLowerCase()) !== -1) ||
    //     (d.amount && d.amount.indexOf(serchValue) !== -1) ||
    //     (d.patient_appointment_id &&
    //       d.patient_appointment_id.toString() &&
    //       d.patient_appointment_id.toString().indexOf(serchValue) !== -1)
    //     );
    // });
    const closeModal = () => {
        setIsArrangementDetails(false)
    }
    const handleArrangementChange = (event) => {
        const newInput = Object.assign({}, input)
        newInput[event.target.name] = event.target.value
        // input.discount_by_physician > 0 && parseInt(input.discount_amount) > 0
        if (
            newInput.discount_by_physician > 0 &&
            arrangementDetails.discount_amount > 0
        ) {
            newInput.discount_amount =
                (newInput.original_amount * newInput.discount_by_physician) /
                100
            newInput.adjusted_amount =
                newInput.original_amount -
                (newInput.original_amount * newInput.discount_by_physician) /
                    100
        } else {
            // newInput.discount_amount = newInput.original_amount - newInput.discount_amount
            newInput.adjusted_amount =
                newInput.original_amount - newInput.discount_amount
        }
        setInput(newInput)
    }
    const updateReviewArrangementFunc = () => {
        const {
            patient_appointment_id,
            original_amount,
            discount_amount,
            adjusted_amount,
            discount_by_physician,
            months,
            patient_id,
        } = input
        const data = {
            patient_appointment_id: patient_appointment_id,
            original_amount: original_amount && original_amount.toString(),
            discount_amount: discount_amount && discount_amount.toString(),
            adjusted_amount: adjusted_amount && adjusted_amount.toString(),
            discount_by:
                discount_by_physician && discount_by_physician.toString(),
            months: months && months.toString(),
            patient_id: patient_id && patient_id.toString(),
        }
        const id = arrangementDetails.patient_payment_id
        dispatch(updateArrangement(data, id)).then((response) => {
            if (response.statusCode === 200) {
                setSuccessAlert(true)
                setSuccessAlertMessage(
                    'You have successfully accepted this arrangement'
                )
                setArrangementDetails(false)
                dispatch(getPatientReviewListing('')).then((res) => {
                    if (res.statusCode === 200) {
                        setIsArrangementDetails(false)
                        const ArrayData = res.data.review_arrangements.filter(
                            (item) => item.is_arrangement_accepted === 0
                        )
                        setFilteredData(ArrayData.splice(0, 3))
                        //         setFilteredData(
                        //             res.data &&
                        // res.data.review_arrangements &&
                        // res.data.review_arrangements.splice(0, 3)
                        //         );
                        setPaidAmount(res.data.paid_amounts)
                        setUnPaidAmount(res.data.unpaid_amounts)
                    }
                })
            }
        })
    }
    const closeSuccessAlert = () => {
        setSuccessAlert(false)
    }
    // const updateSearch = (event) => {
    //     setSerchValue(event.target.value.substr(0, 20));
    // };
    const totalAmountOnDashboard =
        parseFloat(paidAmount) + parseFloat(unPaidAmount)

    // const handleDownloadPdf = () => {
    //     dispatch(downloadArrangementPdf())
    // }
    const handleDownloadPdf = (event) => {
        // const { selectedData } = this.state
        const tempArray = []
        const allIdsArray = []
        selectedData &&
            selectedData.map((row) => {
                return tempArray.push(row.patient_payment_id)
            })

        tableData &&
            tableData.map((d) => {
                if (d.is_arrangement_accepted === 0) {
                    return allIdsArray.push(d.patient_payment_id)
                }
            })
        const data = {
            is_arrangement_accepted: 1,
            patient_payment_ids: tempArray,
        }
        const allIdData = {
            is_arrangement_accepted: 1,
            patient_payment_ids: allIdsArray,
        }
        const finalData = tempArray && tempArray.length > 0 ? data : allIdData
        if (event.target.value === 'selected items') {
            if (tempArray.length == 0 && allIdsArray.length == 0) {
                setSuccessAlert(true)
                setSuccessAlertMessage(
                    'Your all arrangements are already selected'
                )
            } else {
                dispatch(acceptPatientArrangement(finalData)).then(
                    (response) => {
                        if (response.statusCode === 200) {
                            setSuccessAlert(true)
                            setSuccessAlertMessage(
                                'You have successfully accepted this arrangement'
                            )
                            setIsArrangementDetails(false)
                            dispatch(getPatientReviewListing('')).then(
                                (res) => {
                                    if (res.statusCode === 200) {
                                        const ArrayData =
                                            res.data.review_arrangements.filter(
                                                (item) =>
                                                    item.is_arrangement_accepted ===
                                                    0
                                            )
                                        setFilteredData(ArrayData.splice(0, 3))
                                        //             setFilteredData(
                                        //                 res.data &&
                                        //   res.data.review_arrangements &&
                                        //   res.data.review_arrangements.splice(0, 3)
                                        //             );
                                        setPastDuePayments(
                                            res.data.past_dues_payments
                                        )
                                        setPieChartData(res.data.pie_chart_data)
                                        setBarChartData(res.data.bar_graph_data)
                                        setPaidAmount(res.data.paid_amounts)
                                        setUnPaidAmount(res.data.unpaid_amounts)
                                    }
                                }
                            )
                        }
                    }
                )
            }
        } else {
            dispatch(downloadArrangementPdf())
        }
    }
    return (
        <>
            {!isMobile && (
                <div className="patient_list physician-dashboard-page">
                    <nav className="navbar navbar-expand-lg navbar-light navbar_horizontal patient_navbar z-index1">
                        <PompayTopLogo
                            className="small_logo patient_list_logo"
                            ancorClassName=""
                        />
                        <ul className="navbar-nav mr-auto patient_list_custom_navbar">
                            <div className="vertical-dash"></div>
                            {/* <li className="nav-item search_wrapper d-flex align-items-center">
                                <form className="form-inline">
                                    <MdSearch className="color_dark_grey font20 m-2 ml-3" />
                                    <input
                                        className="form-control mr-sm-2 patient_search"
                                        type="search"
                                        name="patientSearchParam"
                                        value={ serchValue || '' }
                                        onChange={ updateSearch }
                                        placeholder="Search by name, patient I.D. "
                                        aria-label="Search"
                                    />
                                </form>
                            </li> */}
                        </ul>
                        {isLoading && (
                            <div className="mt-5 mx-4">
                                {' '}
                                <Loading />{' '}
                            </div>
                        )}
                        {!isLoading && (
                            <>
                                <div className="d-flex patient_right_nav">
                                    <div className="d-flex margin_left_right20 font12 align-items-center">
                                        <span className="mr-4 balance-label">
                                            Account Balance
                                        </span>
                                        <span className="pay-amount">
                                            {currencyFormatter.format(
                                                totalAmount || 0
                                            )}
                                        </span>
                                    </div>
                                    <div className="d-flex font12 align-items-center">
                                        <button
                                            className="btn pay-btn pointer"
                                            onClick={() =>
                                                props.history.push({
                                                    pathname: '/account',
                                                    state: {
                                                        bankTab: true,
                                                    },
                                                })
                                            }
                                        >
                                            Transfer
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}
                    </nav>
                    <div className="wrapper">
                        <SidebarNavigation history={props.history} />
                        <div id="content">
                            <div className="physician-dashboard-wrapper">
                                <div className="row">
                                    <div className="col-7 pr-2">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row no-gutters">
                                                    <div className="col-3">
                                                        <div className="d-flex justify-content-center">
                                                            <div className="total_amount">
                                                                {/* {currencyFormatter.format(
                                                                    totalAmountOnDashboard
                                                                )} */}
                                                                {totalAmountOnDashboard
                                                                    ? currencyFormatter.format(
                                                                          totalAmountOnDashboard
                                                                      )
                                                                    : '$0.00'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className="form-group m-0">
                                                            <div className="select-item-dropdown-wrapper">
                                                                <div className="form-group m-0">
                                                                    <label className="wrap-Selected-Items wrap-Selected-date">
                                                                        <select
                                                                            className="form-control select-item-dropdown date_drop_down"
                                                                            name="print"
                                                                        >
                                                                            <option>
                                                                                Week-to-date
                                                                            </option>
                                                                        </select>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-3 offset-3">
                                                        <div className="d-flex flex-column float-right">
                                                            <span className="d-flex align-items-center justify-content-between mt-1">
                                                                <span className="d-flex align-items-center">
                                                                    <GoPrimitiveDot className="font24 paid-dot mx-1" />
                                                                    <span className="nav-label">
                                                                        Paid
                                                                        &nbsp;
                                                                    </span>
                                                                </span>
                                                                <span className="specific_amount">
                                                                    {/* {numberFormatter(paidAmount)} */}
                                                                    {paidAmount
                                                                        ? numberFormatter(
                                                                              paidAmount
                                                                          )
                                                                        : numberFormatter(
                                                                              0
                                                                          )}
                                                                </span>
                                                            </span>
                                                            <span className="d-flex align-items-center justify-content-between my-1">
                                                                <span className="d-flex align-items-center">
                                                                    <GoPrimitiveDot className="font24 unpaid-dot mx-1" />
                                                                    <span className="nav-label">
                                                                        Unpaid
                                                                        &nbsp;
                                                                    </span>
                                                                </span>
                                                                <span className="specific_amount">
                                                                    {/* {numberFormatter(unPaidAmount)} */}
                                                                    {unPaidAmount
                                                                        ? numberFormatter(
                                                                              unPaidAmount
                                                                          )
                                                                        : numberFormatter(
                                                                              0
                                                                          )}
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="bar-chart-wrapper">
                                                    <Bar
                                                        pointstyle="star"
                                                        data={{
                                                            labels: finalBardata.labels,
                                                            responsive: true,
                                                            offset: true,
                                                            datasets: [
                                                                {
                                                                    label: 'paid',
                                                                    pointstyle:
                                                                        'rectRounded',
                                                                    backgroundColor:
                                                                        '#5164F4',
                                                                    barThickness: 40,
                                                                    categoryPercentage: 1,
                                                                    data: finalBardata
                                                                        .previousDate
                                                                        .dataSet,
                                                                },
                                                                {
                                                                    label: 'unpaid',
                                                                    backgroundColor:
                                                                        '#F2F5F4',
                                                                    barThickness: 40,
                                                                    categoryPercentage: 1,
                                                                    pointstyle:
                                                                        'triangle',
                                                                    data: finalBardata
                                                                        .currentDate
                                                                        .dataSet,
                                                                },
                                                            ],
                                                        }}
                                                        width={100}
                                                        height={50}
                                                        options={{
                                                            plugins: {
                                                                tooltip:
                                                                    {
                                                                        callbacks:
                                                                            {
                                                                                label: function (context) {
                                                                                    return context.label + ': ' + currencyFormatter.format(parseFloat(context.raw))
                                                                                },
                                                                            },
                                                                    },
                                                            },
                                                            offsetGridLines: true,
                                                            drawTicks: true,
                                                            layout: {
                                                                padding: {
                                                                    top: 30,
                                                                    right: 40,
                                                                    bottom: 40,
                                                                },
                                                            },
                                                            legend: {
                                                                display: true,
                                                                position:
                                                                    'right',
                                                                align: 'start',
                                                                labels: {
                                                                    usePointStyle: true,
                                                                },
                                                            },
                                                            responsive: true,
                                                            maintainAspectRatio: false,
                                                            scales: {
                                                                xAxes: [
                                                                    {
                                                                        stacked: true,
                                                                        ticks: {
                                                                            padding: 5,
                                                                        },
                                                                        gridLines:
                                                                            {
                                                                                display: false,
                                                                            },
                                                                    },
                                                                ],
                                                                yAxes: [
                                                                    {
                                                                        stacked: false,
                                                                        gridLines:
                                                                            {
                                                                                display: false,
                                                                            },
                                                                        ticks: {
                                                                            beginAtZero: true,
                                                                            maxTicksLimit: 6,
                                                                            padding: 20,
                                                                            callback(
                                                                                n
                                                                            ) {
                                                                                if (
                                                                                    n <
                                                                                    1e3
                                                                                )
                                                                                    return n
                                                                                if (
                                                                                    n >=
                                                                                    1e3
                                                                                )
                                                                                    return (
                                                                                        +(
                                                                                            n /
                                                                                            1e3
                                                                                        ).toFixed(
                                                                                            1
                                                                                        ) +
                                                                                        'K'
                                                                                    )
                                                                            },
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-5 pl-1">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="text-left position-absolute">
                                                    <div className="d-flex align-items-end">
                                                        <span className="bold-text">
                                                            Past Due
                                                            <br /> Payments
                                                        </span>
                                                        <MdChevronRight
                                                            className="font16 pointer"
                                                            onClick={() =>
                                                                props.history.push(
                                                                    {
                                                                        pathname:
                                                                            '/payments',
                                                                        state: {
                                                                            pastDuePage: true,
                                                                        },
                                                                    }
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <div className="mt-1">
                                                        <span className="total-label">
                                                            Total
                                                        </span>
                                                        <span className="total-amount">
                                                            {/* {currencyFormatter.format(pastDuePayments)} */}
                                                            {pastDuePayments
                                                                ? currencyFormatter.format(
                                                                      pastDuePayments
                                                                  )
                                                                : numberFormatter(
                                                                      0
                                                                  )}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-end">
                                                    <div className="Doughnut-chart-wrapper">
                                                        {pieChartData[0]
                                                            ?.total_amount ==
                                                            0 &&
                                                        pieChartData[1]
                                                            ?.total_amount ==
                                                            0 &&
                                                        pieChartData[2]
                                                            ?.total_amount ==
                                                            0 &&
                                                        pieChartData[3]
                                                            ?.total_amount ==
                                                            0 ? (
                                                            <div
                                                                style={{
                                                                    height: '415px',
                                                                    width: '400px',
                                                                    font: '20px "Helvetica Neue Bold"',
                                                                    lineHeight:
                                                                        '22px',
                                                                    color: '#444B48',
                                                                    marginRight:
                                                                        '2px',
                                                                    display:
                                                                        'flex',
                                                                    justifyContent:
                                                                        'center',
                                                                    alignItems:
                                                                        'center',
                                                                }}
                                                            >
                                                                No data found
                                                                for Past Due
                                                                Payment
                                                            </div>
                                                        ) : (
                                                            <Doughnut
                                                                data={donutData}
                                                                options={{
                                                                    plugins: {
                                                                        tooltip:
                                                                            {
                                                                                callbacks:
                                                                                    {
                                                                                        label: function (context) {
                                                                                            return context.label + ': ' + currencyFormatter.format(parseFloat(context.formattedValue))
                                                                                        },
                                                                                    },
                                                                            },
                                                                    },
                                                                }}
                                                                className="Doughnut-chart"
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body p-0">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="header-section d-flex justify-content-between align-items-center p-4">
                                                            <div
                                                                onClick={() =>
                                                                    props.history.push(
                                                                        '/review-arrangement'
                                                                    )
                                                                }
                                                            >
                                                                <span className="bold-text">
                                                                    Review
                                                                    Arrangements
                                                                    (
                                                                    {tableData &&
                                                                        tableData.length}
                                                                    )
                                                                </span>
                                                                <MdChevronRight className="font16" />
                                                            </div>
                                                            <div className="select-item-dropdown-wrapper">
                                                                <div className="form-group m-0">
                                                                    <label className="wrap-Selected-Items">
                                                                        <select
                                                                            className="form-control select-item-dropdown selected"
                                                                            name="print"
                                                                            onChange={
                                                                                handleDownloadPdf
                                                                            }
                                                                        >
                                                                            <option
                                                                                className="custom-option"
                                                                                defaultValue="select day"
                                                                            >
                                                                                {' '}
                                                                                Selected
                                                                                Items
                                                                            </option>
                                                                            <option
                                                                                className="custom-option"
                                                                                name="print"
                                                                                value="today"
                                                                            >
                                                                                Print
                                                                                Transactions
                                                                            </option>
                                                                            <option
                                                                                className="custom-option"
                                                                                name="selected"
                                                                                value="selected items"
                                                                            >
                                                                                {selectedData &&
                                                                                selectedData.length >
                                                                                    0
                                                                                    ? 'Accept Selected'
                                                                                    : 'Accept All'}
                                                                            </option>
                                                                        </select>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        {isLoading && (
                                                            <div className="mt-5 mx-4">
                                                                <Loading />
                                                            </div>
                                                        )}
                                                        {!isLoading && (
                                                            <Table
                                                                rowSelection={{
                                                                    type: 'checkbox',
                                                                    ...rowSelection,
                                                                }}
                                                                columns={
                                                                    columns
                                                                }
                                                                dataSource={
                                                                    tableData
                                                                }
                                                                scroll={{
                                                                    x: 900,
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isArrangementDetails && (
                <PatientArrangementDetailsModal
                    arrangementDetails={arrangementDetails}
                    isOpen={isArrangementDetails}
                    closeModal={closeModal}
                    input={input}
                    history={props.history}
                    handleArrangementChange={handleArrangementChange}
                    updateReviewArrangementFunc={updateReviewArrangementFunc}
                />
            )}
            {isMobile && (
                <PhysicianDashboardMobile
                    finalBardata={finalBardata}
                    totalAmount={totalAmount}
                    availableToPayOutAmount={availableToPayOutAmount}
                    paidAmount={paidAmount}
                    unPaidAmount={unPaidAmount}
                    history={props.history}
                    totalAmountOnDashboard={totalAmountOnDashboard}
                    pastDuePayments={pastDuePayments}
                    donutData={donutData}
                />
            )}
            {successAlert && (
                <SuccessAlert
                    isOpen={successAlert}
                    closeSuccessAlert={closeSuccessAlert}
                    message={successAlertMessage}
                />
            )}
        </>
    )
}

PhysicianDashboard.propTypes = {
    history: PropTypes.object,
}

export default PhysicianDashboard
